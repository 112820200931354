import React from 'react'

import { IEditorialBlockContent } from '../../App/App.types'
import { Context as AppContext } from '../AppShellData/Context'

const replaceVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

export const useEpiLink = () => {
  const links = React.useContext(AppContext)?.content?.lankar ?? {}
  return (key: string) => links?.[key] ?? ''
}
export const useEpiString = () => {
  const strings = React.useContext(AppContext)?.content?.innehall ?? {}
  return (key: string, variables?: { [key: string]: string }) =>
    strings?.[key] ? replaceVariables(strings[key], variables) : key
}
export const useEpiEditorialBlock = (key: 'allmanInfo'): IEditorialBlockContent => {
    return React.useContext(AppContext)?.content?.[key] ?? {mainBody: `Content for key:${key} not found`, name: ''} 
}
