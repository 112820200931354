import { useContext } from 'react'

import { IAppShellConfiguration } from '../../App/App.types'

import { Context } from './Context'

export const useAppShellData = <TResult = unknown>(
  selector: (data: IAppShellConfiguration | null) => TResult
): TResult => {
  const data = useContext(Context)
  return selector({
    ...data,
    user: {
      ...data?.user,
    },
  } as IAppShellConfiguration)
}
