import { DeepPartial } from '@reduxjs/toolkit'

import { AdvisorApprovalStatus } from './AdvisorApprovalStatus.types'
import { IUtbildningsbokning } from './IUtbildningsbokning.types'
import { SupplierResponse } from './SupplierResponse.types'

export interface IUtbildningBokningStatuses {
  isRejectedByAdvisor: boolean | null
  isApprovedByAdvisor: boolean | null
  isWaitingForAdvisorApproval: boolean | null
  isCancelledBySupplier: boolean | null
  isRejectedBySupplier: boolean | null
  isWaitingForSupplierConfirmation: boolean | null
  isAcceptedBySupplier: boolean | null
  isCancelledByClient: boolean | null
  isLateCancelledByClient: boolean | null
}
export interface IUtbildningsbokningGetModel {
  id: string
  educationName: string
  educationLink: string
  supplierResponseAccepted: boolean
  instituteName: string
  placeDescription: string
  isDistance: boolean
  lengthInDays: number
  startDate: string
  bookerEmail: string
  supplierResponse: string
  advisorApprovalStatus: string
  attendanceReport?: {
    attendance: true
    recievedDate: string
    submittedBy: string
    comment: string
  }
  courseFee?: {
    fee: number
    vat: number
    totalFee: number
  }
  booking?: {
    date: string
    reference: string
  }
  supplier?: {
    contactPersonName: string
    email: string
    phone: string
  }
  statuses: IUtbildningBokningStatuses 
}

export const mapUtbildningsBokningToViewModel = (
  booking: DeepPartial<IUtbildningsbokningGetModel>
): IUtbildningsbokning => {
  return {
    id: booking.id ?? '',
    instituteName: booking.instituteName ?? '',
    educationName: booking.educationName ?? '',
    startDate: booking.startDate ? new Date(booking.startDate) : undefined,
    contactEmail: booking.bookerEmail ?? '',
    advisorStatus: booking.advisorApprovalStatus as AdvisorApprovalStatus,
    supplierStatus: booking.supplierResponse as SupplierResponse,
    statuses: {
      isRejectedByAdvisor: booking.statuses?.isRejectedByAdvisor ?? null,
      isApprovedByAdvisor: booking.statuses?.isApprovedByAdvisor ?? null,
      isWaitingForAdvisorApproval: booking.statuses?.isWaitingForAdvisorApproval ?? null,
      isCancelledBySupplier: booking.statuses?.isCancelledBySupplier ?? null,
      isRejectedBySupplier: booking.statuses?.isRejectedBySupplier ?? null,
      isWaitingForSupplierConfirmation: booking.statuses?.isWaitingForSupplierConfirmation ?? null,
      isAcceptedBySupplier: booking.statuses?.isAcceptedBySupplier ?? null,
      isCancelledByClient: booking.statuses?.isCancelledByClient ?? null,
      isLateCancelledByClient: booking.statuses?.isLateCancelledByClient ?? null,
    }
  }
}
