import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import React from 'react'
import { Provider } from 'react-redux'

import { MainContainer } from '../Components'
import UtbildnignsbokningarDetails from '../Utbildningsbokningar/UtbildningsbokningarDetails/UtbildningsbokningarDetails'
import UtbildningsbokningarList from '../Utbildningsbokningar/UtbildningsbokningarList/UtbildningsbokningarList'
import { AppShellDataProvider } from '../utils/AppShellData'
import { AppShellRouter, KeyRoute } from '../utils/Router'
import { store } from '../utils/store/configureStore'

import { IAppShellConfiguration } from './App.types'

const App: React.FunctionComponent<IAppShellConfiguration> = (
  appShellConfig
) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppShellDataProvider value={appShellConfig}>
          <MainContainer>
            <AppShellRouter currentKey={appShellConfig.currentKey}>
              <KeyRoute urlKey="list">
                <UtbildningsbokningarList />
              </KeyRoute>
              <KeyRoute urlKey="details">
                <UtbildnignsbokningarDetails />
              </KeyRoute>
            </AppShellRouter>
          </MainContainer>
        </AppShellDataProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
