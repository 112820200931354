import React from 'react'
import { date } from '@trr/frontend-datetime'
import { List, ListItem, Typography } from '@mui/material'

import { useEpiString } from '../../../../utils/Context/EpiContext'
import { useUtbildningsBokning } from '../../../../utils/Context/UtbildningsbokningContext'


const BookingDetailsListItemText = ({
  label,
  value,
  testid,
}: {
  label: string
  value: string
  testid?: string
}) => {
  const ariaLabel = `booking-detail-label-${label}`
  return (
    <ListItem sx={{border:'none'}}>
      <Typography sx={{ width: '280px' }} variant="body1" id={ariaLabel}>
        {label}
      </Typography>
      <Typography
        data-testid={testid}
        aria-labelledby={ariaLabel}
        sx={{ width: '280px', fontWeight:'bold' }}
        variant="body1"
      >
        {value}
      </Typography>
    </ListItem>
  )
}

const DetailedInformation: React.FC = () => {
  const epi = useEpiString()

  const {
    startDate,
    educationName: title,
    id: bookingId,
  } = useUtbildningsBokning()

  return (
    <List dense sx={{ mt: 4 }}>
      <BookingDetailsListItemText
        label={epi('education')}
        value={title ?? ''}
        testid="details-booking-name"
      />
      <BookingDetailsListItemText
        label={epi('educationStart')}
        value={
          startDate
            ? date.format(startDate, {
                year: true,
              })
            : '-'
        }
      />
      <BookingDetailsListItemText
        label={epi('bookingNumber')}
        testid="details-booking-number"
        value={bookingId ?? ''}
      />
    </List>
  )
}

export default DetailedInformation
