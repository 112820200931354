import { DeepPartial } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { IUtbildningsbokningGetModel, IUtbildningsbokningListItemGetModel } from '../../Utbildningsbokningar/types'
import getConfig from '../Config/configService'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const utbildningsbokningarApi = createApi({
  reducerPath: 'utbildningsbokningarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/Booking/`,
    prepareHeaders,
  }),
  tagTypes: ['bokningar'],
  endpoints: (build) => ({
    getUtbildningar: build.query<
      DeepPartial<IUtbildningsbokningListItemGetModel>[],
      string
    >({
      query: () => ({ url: `/user` }),
    }),
    getUtbildning: build.query<
      DeepPartial<IUtbildningsbokningGetModel>,
      string
    >({
      query: (bookingId) => ({ url: `/user/${bookingId}` }),
    }),
  }),
})

export const { useGetUtbildningQuery, useGetUtbildningarQuery } =
  utbildningsbokningarApi
