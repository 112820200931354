// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tG9AUvyXmOeNdympPfbp{word-wrap:break-word}.tG9AUvyXmOeNdympPfbp a{color:#6222c3;text-decoration:none;display:inline-flex !important;align-items:center;font-weight:500;vertical-align:top}.tG9AUvyXmOeNdympPfbp a:hover{cursor:pointer;text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/Components/WysiwygEditor/WysiwygEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,wBACE,aAAA,CACA,oBAAA,CACA,8BAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CAEA,8BACE,cAAA,CACA,yBAAA","sourcesContent":[".WysiwygEditor {\n  word-wrap: break-word;\n  a {\n    color: rgb(98, 34, 195);\n    text-decoration: none;\n    display: inline-flex !important;\n    align-items: center;\n    font-weight: 500;\n    vertical-align: top;\n\n    &:hover {\n      cursor: pointer;\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WysiwygEditor": `tG9AUvyXmOeNdympPfbp`
};
export default ___CSS_LOADER_EXPORT___;
