import React from 'react'

import s from './WysiwygEditor.module.scss'

interface IWysiwygEditorProps {
  htmlFormattedString: string
}

const WysiwygEditor: React.FC<IWysiwygEditorProps> = ({
  htmlFormattedString,
}) => {
  //Epi returns whitespaces in brödtextblock as <p> </p>
  const stringWithWhiteSpace = htmlFormattedString.replaceAll(
    '<p> </p>',
    '&nbsp'
  )
  //Epi returns links not opening in new tab
  const linksTargetBlank = stringWithWhiteSpace.replaceAll(
    '<a',
    '<a target="_blank" '
  )
  if (!htmlFormattedString) return null
  return (
    <div
      className={s.WysiwygEditor}
      dangerouslySetInnerHTML={{ __html: linksTargetBlank }}
    />
  )
}

export default WysiwygEditor
